import * as React from 'react'
import Layout from '../components/layout'
import { hpmain, fitImage, hpmaintxt, hpslogan } from '../components/layout.module.css'
import { Link } from 'gatsby'

import { divCenter, cardCat, txtBlack, iframeCard, card_sh, card_sh_o, iframeSize, all_favors_img } from '../components/layout.module.css'
import cat_earrings from '../images/prodcat/cat_earrings.png';
import cat_necklaces from '../images/prodcat/cat_necklaces.png';
import cat_tray from '../images/prodcat/cat_tray.png';
import cat_favors from '../images/prodcat/cat_favors.png';
import all_favors from '../images/all_favors.png';

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <div className={hpmain}>

        <div className={hpslogan}>&#9825; Make your life full of creativity with a craft. &#9825;</div>
        <br></br>
        <h2>Μπομπονιέρες</h2>
        <div className={hpslogan}><b>Στο Joan Crafts επιλέγουμε η μπομπονιέρα να αποτελεί "χρηστικό ή διακοσμητικό αντικείμενο". Τι σημαίνει αυτό;</b></div>
        <br></br>
        <div className={hpslogan}>Στην κατηγορία αυτή το αντικείμενο παύει να είναι απλά ένα στοιχείο που στολίζει τη μπομπονιέρα και αποκτά σημαντικό ρόλο, είτε χρηστικό είτε διακοσμητικό.</div>
        <br></br>
        <div className={hpslogan}>Τα κουφέτα θα φαγωθούν και το μαντήλι/τούλι ίσως πεταχτεί, όμως το αντικείμενο θα φανεί χρήσιμο, θα αξιοποιηθεί απο τους καλεσμένους και θα μείνει ως ενθύμιο απο την ιδιαίτερη αυτή ημέρα. &#129505;</div>
        <br></br>
        <p><img className={all_favors_img} src={all_favors} /> </p>
        <br></br>

        <Link to="/favors">
          <div>
            <article>
              <h4 className={hpmaintxt}>
                Βρείτε πρωτότυπες μπομπονιέρες γάμου ή βάπτισης, για να εντυπωσιάσετε τους καλεσμένους σας &#8594;
              </h4>
            </article>
          </div>
        </Link>

        <br></br>
        <div className={card_sh_o}>

        <h3>ΚΑΤΗΓΟΡΙΕΣ</h3>
        <div className={divCenter}>

          <Link to="/trays">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Δίσκοι
                </h3>
              </article>
            </div>
          </Link>

          <Link to="/holders">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Βάσεις
                </h3>
              </article>
            </div>
          </Link>

          <Link to="/coasters">
            <div className={cardCat}>
              <article>
                <h3 className={txtBlack}>
                  Σουβέρ
                </h3>
              </article>
            </div>
          </Link>

        </div>

        <Link to="/products">
          <div>
            <article>
              <h4 className={hpmaintxt}>
                ΠΕΡΙΣΣΟΤΕΡΕΣ ΚΑΤΗΓΟΡΙΕΣ &#8594;
              </h4>
            </article>
          </div>
        </Link>

        </div>

        <div className={card_sh_o}>
          &#x1F338;
          <h4>
            Βρείτε μας στο Instagram
          </h4>
          <div className={iframeCard}>
            <div className={card_sh}>
              <iframe className={iframeSize} src="https://www.instagram.com/joancraftsgr/embed/" frameborder="0"></iframe>
            </div>
          </div>
        </div>

      </div>

    </Layout>
  )




}

export default IndexPage